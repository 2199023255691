<div class="main">
  <section class="home">
    <div class="top">
      <div class="logo">Logo.</div>
      <ul class="nav-list">
        <li class="nav-item"><a class="nav-link">Home</a></li>
        <li class="nav-item"><a class="nav-link">Interiors</a></li>
        <li class="nav-item"><a class="nav-link">Products</a></li>
        <li class="nav-item"><a class="nav-link">About</a></li>
        <li class="nav-item"><a class="nav-link">Contact</a></li>
      </ul>
      <div class="menu">Kennismaken</div>
    </div>
    <h1 class="heading-main">aspernatur officia deserunt minima harum.</h1>
    <div class="bottom">
      <div class="bottom-left">Porro facere possimus inventore illo.</div>
      <div class="bottom-right">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel quidem
        architecto ipsam numquam.
      </div>
    </div>
  </section>
  <div class="content">
    <section class="scroll-content one">
      <div class="center-img">
        <figure class="img">
          <img
            src="./assets/images/photo-1610123172705-a57f116cd4d9.jpeg"
            alt=""
          />
        </figure>
        <div class="img-text">
          <div class="bold">sit amet consectetur adipisicing</div>
          <div class="normal">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div>
        </div>
      </div>
    </section>
    <section class="scroll-content two">
      <div class="center-img">
        <figure class="img preview">
          <img
            src="./assets/images/photo-1611431182735-68e6ffb168dd.jpeg"
            alt=""
          />
        </figure>
        <div class="img-text">
          <div class="bold">sit amet consectetur adipisicing</div>
          <div class="normal">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div>
        </div>
      </div>
    </section>
    <section class="scroll-content three">
      <h1 class="heading-intro">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
        recusandae qui. Iste, deleniti.
      </h1>
      <div class="article">
        <div class="left">
          <figure>
            <img
              src="./assets/images/photo-1582402516762-5c3e03e846d0.jpeg"
              alt=""
            />
          </figure>
        </div>
        <div class="right">
          <div class="block">
            <h1>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
              inventore quas sapiente expedita vel rerum dolor distinctio beatae
              doloribus! Quod dolorem libero sunt quidem doloribus ea temporibus
              nisi possimus consequatur?
            </p>
          </div>
          <div class="block">
            <h1>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
              inventore quas sapiente expedita vel rerum dolor distinctio beatae
              doloribus! Quod dolorem libero sunt quidem doloribus ea temporibus
              nisi possimus consequatur?
            </p>
          </div>
          <div class="block-1">Lorem ipsum dolor sit amet consectetur.</div>
        </div>
      </div>
    </section>
  </div>
</div>
